import { useState } from "react";
import useDetails from "../hooks/useDetails";
import {
  OtherPaymentOptions,
  numberWithCommas,
} from "../utils/appParams";
import StudentInfo from "./StudentInfo";
import "./Style.css";
import { useEffect } from "react";

const OtherFeesComponent = ({
  loading,
  btnText,
  handlePayment,
  schoolFee,
  paymentType,
  setPaymentType,
}) => {
  const { data } = useDetails();
  //   const {data:datas} = useProspective()
  let details = OtherPaymentOptions?.find(option => option.name.toLowerCase() === paymentType.toLowerCase())

  const handleSelection = (payment) => {
    setPaymentType(payment.target.value);
  };


  return (
    <>
      {data && (
        <div>
          <div class=" col-md-12 card p-5" style={{ padding: "10px" }}>
            <StudentInfo data={data} />
            <div className="col-lg-12 bg-light p-5">
              <h4>Payment for:</h4>
              <select
                className="form-control col-lg-6"
                value={paymentType}
                onChange={handleSelection}
              >
                <option value="">--Select Payment--</option>
                {OtherPaymentOptions?.map((option) => (
                  <option value={(option.value)?.toLowerCase()}>{option.name}</option>
                ))}
              </select>
            </div>
            {paymentType && (
              <>
                <div class="pricing p-3 rounded mt-4 mb-4 d-flex justify-content-between">
                    <h4>{schoolFee && schoolFee?.payment}</h4>
                  <div class="d-flex flex-row align-items-center">
                    <sup class="dollar font-weight-bold">₦</sup>
                    <span class="amount ml-1 mr-1">
                      {/* {schoolFee && (schoolFee?.amount)} */}
                      {details.amount}
                    </span>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    class="btn btn-primary btn-raised waves-effect"
                    onClick={handlePayment}
                    disabled={loading}
                  >
                    {btnText} <i class="fa fa-long-arrow-right"></i>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OtherFeesComponent;
