export let URL = process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_LOCAL_URL : (process.env.REACT_APP_ENV === 'staging' ? process.env.REACT_APP_STAGING_URL : (process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_URL : ''));

// export let auth_token = localStorage.getItem('student-token');

export const asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  };

  export const auth_token = () => {
    return localStorage.getItem('student-token') 
  }

  export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  export const paymentOptions = [
    {
      name: "School Fees - Full Payment",
      value:'full'
    },
    {
      name: "School Fees - Part Payment",
      value:'part'
    },
    {
      name: "Late Payment",
      value:'late'
    },
  ]

  export const OtherPaymentOptions = [
    {
      name: "ND Certificate",
      value: "ND Certificate",
      amount: "10,000"
    },
    {
      name: "HND Certificate",
      value: "HND Certificate",
      amount: "12,000"
    },
    {
      name: "Late registration",
      value: "Late registration",
      amount: "6,000"
    },
    {
      name: "Statement of result",
      value: "Statement of result",
      amount: "500"
    },
    {
      name: "Transcript(International)",
      value: "Transcript(International)",
      amount: "15,000"
    },
    {
      name: "Transcript(Local)",
      value: "Transcript(Local)",
      amount: "5,000"
    },
    {
      name: "Project binding",
      value: "Project binding",
      amount: "2,000"
    },
    {
      name: "SIWES",
      value: "SIWES",
      amount: "2,000"
    },
    {
      name: "Convocation",
      value: "Convocation",
      amount: "6,000"
    },
    {
      name: "Endowment",
      value: "Endowment",
      amount: "3,000"
    },
    {
      name: "Chest X-RAY",
      value: "Chest X-RAY",
      amount: "2,000"
    },
    {
      name: "Frontier journal",
      value: "Frontier journal",
      amount: "1,500"
    },
    {
      name: "Matriculation gown",
      value: "Matriculation gown",
      amount: "2,000"
    },
    {
      name: "Facility upgrade",
      value: "Facility upgrade",
      amount: "4,000"
    },
  ]