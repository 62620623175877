import { useContext } from "react";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ChevronDown, ChevronUp, Trash2 } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import Footer from "../../components/Footer";
import api from "../../api/api";
import { getCurrentUser } from "../../utils/authService";
import useDetails from "../../hooks/useDetails";
import { GetStudentDetails } from "../../api/student";
import usePayments from "../../hooks/usePayments";
import StudentInfo from "../../components/StudentInfo";


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';

const CourseRegistration = () => {
  const { data } = useDetails();
  const paymentComplete = data?.payment_complete
  console.log("pauyment", paymentComplete)
  const {payment} = usePayments()
  const [level, setLevel] = useState();
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [mappedCourses, setMappedCourses] = useState([])
  const [registeredCourses, setRegisteredCourses] = useState([])
  const [registeredShow, setRegisteredShow] = useState(false)
  const [validation, setValidation] = useState()
  const [rowClick, setRowClick] = useState(true);
  const [totalSelectedUnits, setTotalSelectedUnits] = useState(0);
  const [departments, setDepartments] = useState([])
  const [department, setDepartment] = useState()
  const [faculty, setFaculty] = useState([])
  const [filteredCourses, setFilteredCourses] = useState([])
  const [selectedFilteredCourses, setSelectedFilteredCourses] = useState()
  const [query, setQuery] = useState("")

  const theCourses = mappedCourses?.map(course => course.course_id)
  
  const [grade, setGrade] = useState("");
  const [semester, setSemester] = useState();

  const [toggle, setToggle] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const user = getCurrentUser();

  const token = user?.access;

  const totalUnits = selectedCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const totalRegisteredUnits = registeredCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  console.log("totalUnits", totalUnits);

  const getDetails = async () => {
    setLoading(true);
    try {
      const data = await GetStudentDetails(user);
      setLevel(data?.level);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  

  //   const fetchPaymentStatus = async () => {
  //     try {
  //       const { data } = await GetPaymentStatus(user.application_id);
  //       console.log(data, "statuss");
  //       setAdmissionFee(data.application_fee);
  //       setResultFee(data.result_checking_fee);
  //     } catch (error) {}
  //   };

  // const getCourses = async () => {
  //   try {
  //     const { data } = await api.get(
  //       `/courses/all/?level=${level}&semester_int=${semester}`
  //     );
  //     console.log("Courses list", data.data);
  //     setCourses(data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const getMappedCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/mapped/read?mapped_level=${level}&semester_int=${semester}`
      );
      console.log("Mapped list", data.data);
      setMappedCourses(data.data)
      setCourses(data.data)
      
    } catch (error) {
      console.log(error);
    }
  };

  const getFilteredCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/mapped/read?course_id_code=${query}`
      );
      console.log("Queried list", data.data);
      // setFilteredCourses(data.data)
      setFilteredCourses(data.data.map(course => course.course_id))
      
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartments = async () => {
    try {
      const { data } = await api.get(
        `/department/all?page_size=${70}`
      );
      console.log("department list", data?.results);
      setDepartments(data?.results)
      
    } catch (error) {
      console.log(error);
    }
  };

  const getRegisteredCourses = async () => {
    try {
      const { data } = await api.get(`/courses/student-view`);
      console.log("Registered courses", data);
      setRegisteredCourses(data);
    } catch (error) {
      console.log(error);
    }
  };

  const MAX_UNITS = 7;

  const handleSelectionChange = (e) => {
    // Calculate the total units of the selected courses
    const newSelectedCourses = e.value;
    console.log("newSelec-->", newSelectedCourses)
    let totalUnits = 0;
    newSelectedCourses.forEach(theCourses => {
        totalUnits += parseInt(theCourses.unit); // Assuming quantity represents units
    });

    // Check if the total units exceed the allowed maximum
    if (totalUnits <= MAX_UNITS) {
        setSelectedCourses(newSelectedCourses);
        setTotalSelectedUnits(totalUnits);
    } else {
      alert(`Cannot exceed maximum of ${MAX_UNITS} units`)
    }
    // If the total units exceed the limit, don't update the selectedProducts state
};

function isObject(value) {
  return (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value)
  );
}

  const handleCourseSelection = (event) => {
    if (isObject(event)) {
      const course = event
      const newTotalUnits = totalUnits + parseInt(course.unit);

      // Check if adding the new course will exceed the maximum units
    if (newTotalUnits > MAX_UNITS) {
      alert(`Cannot exceed maximum of ${MAX_UNITS} units!`);
      return;
    }

    // Check if course is already in selectedCourses array
    if (
      selectedCourses.some((selectedCourse) => selectedCourse.id === course.id)
    ) {
      alert("Course already selected!");
      return;
    }

    setSelectedCourses((prevSelectedCourses) => [
      ...prevSelectedCourses,
      course,
    ]);
    setQuery('')
    } else{

    const course = JSON.parse(event.target.value);
    const newTotalUnits = totalUnits + parseInt(course.unit);

    // Check if adding the new course will exceed the maximum units
    if (newTotalUnits > MAX_UNITS) {
      alert(`Cannot exceed maximum of ${MAX_UNITS} units!`);
      return;
    }

    // Check if course is already in selectedCourses array
    if (
      selectedCourses.some((selectedCourse) => selectedCourse.id === course.id)
    ) {
      alert("Course already selected!");
      return;
    }

    setSelectedCourses((prevSelectedCourses) => [
      ...prevSelectedCourses,
      course,
    ]);
  }
  };

  const handleCourseDelete1 = (id) => {
    let newList = selectedCourses.filter((item) => item.id !== id);
    setSelectedCourses(newList);
  };

  const validated = async () => {
    try {
      const {data} = await api.get(`/courses/validated/first/${level}`)
      setValidation(data?.data[0])
      console.log("sasass",data?.data[0])
    } catch (error) {
      
    }
  }

  const deleteRegisteredCourses = async (id) => {
    try {
      const {data} = await api.delete(`/courses/remove-registered-course/${id}`)
      getRegisteredCourses()
      toast.success("Course deleted")
    } catch (error) {
      
    }
  }

  const registerCourses = async (e) => {
    e.preventDefault()
    setLoading(true);
    try {
      const { data } = await api.post("/courses/register", {
        courses: selectedCourses,
      });
      console.log("Registered courses", data);
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDetails();
    getRegisteredCourses()
    getDepartments()
    // fetchPaymentStatus();
  }, []);

  useEffect(() => {
    validated()
    getMappedCourses()
  }, [level, semester]);

  useEffect(() => {
    getFilteredCourses()
  }, [query]);

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  return (
    <div class="page-wrapper">
      {(data && courses) &&
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                    <li class="breadcrumb-item active">Registration</li>
                  </ol>
                </div>
                <h4 class="page-title">Registration</h4>
              </div>
            </div>
          </div>
          {(paymentComplete?.toLowerCase() === "full" || paymentComplete?.toLowerCase() === 'part') &&
          <div class="row">
            <div class="col-12">
              <div class="tab-content detail-list" id="pills-tabContent">
                <div class="tab-pane fade show active" id="general_detail">
                  <div class="row">
                    <div class="col-lg-12 col-xl-12 mx-auto">
                      <div class="card">
                        <div class="card-body">
                          <div class="container">
                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>Current Level</label>
                                  <select
                                    class="form-control mb-3"
                                    value={level}
                                    onChange={(e) => setLevel(e.target.value)}
                                    disabled
                                    
                                  >
                                    <option>100</option>
                                    <option>200</option>
                                    <option>300</option>
                                    <option>400</option>
                                  </select>
                                  {/* <button className="btn dspg-success mr-1">
                                    Register
                                  </button> */}
                                </div>
                              </div>
                              <StudentInfo data={data}/>
                              <>
                                <hr />
                                <p className="breadcrumb-item active">
                                  Registration Criteria
                                </p>

                                <div class="form-group row">
                                  <div class="col-md-3">
                                    <label>Semester</label>
                                    <select
                                      class="form-control"
                                      value={semester}
                                      onChange={(e) =>
                                        setSemester(e.target.value)
                                      }
                                    >
                                      <option value="">--Select Semester--</option>
                                      <option value="1">FIRST</option>
                                      <option value="2">SECOND</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group mb-3">
                                  <button
                                    class="btn btn-gradient-primary btn-sm  mb-0"
                                    disabled={loading}
                                    onClick={()=> setRegisteredShow(true)}
                                  >
                                    View Registered Courses
                                  </button>
                                </div>
                                <hr />

                                <hr />
                                {registeredShow ?
                                <>
                                {registeredCourses.length ? (
                                  <>
                                    <p className="breadcrumb-item">
                                      Registered Courses
                                    </p>
                                    <div className="alert dspg-info"><b>Note!</b> You cannot delete courses after the Course Advisor have approved your course registration </div>
                                    <table className="col-lg-9">
                                      <thead className="dspg-dark">
                                        <th>Subject</th>
                                        <th>Grade</th>
                                        <th>Unit</th>
                                        <th>Semester</th>
                                        <th>Status</th>
                                        <th></th>
                                      </thead>
                                      {registeredCourses?.map((list) => (
                                        <tbody>
                                          <td>{list.code}</td>
                                          <td>{list.title}</td>
                                          <td>{list.unit}</td>
                                          <td>{list.semester}</td>
                                          <td>{list.is_approved?'Approved': 'Not Approved'}</td>
                                          {validation?.is_validated ?
                                          <td></td> :
                                          <td
                                            onClick={() =>
                                              deleteRegisteredCourses(list.id)
                                            }
                                          >
                                            <Trash2 className="waves-effect align-self-center icon-sm" />
                                          </td>}
                                        </tbody>
                                      ))}
                                      <tfoot>
                                        <tr>
                                        <td className="h5">
                                          Total Units
                                        </td>
                                        <td></td>
                                        <td className="h5">
                                          {totalRegisteredUnits}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        </tr>
                                        <tr>
                                        <td colSpan={6} className="h5">
                                          Course Advisor: {}
                                        </td>
                                        </tr>
                                        <tr>
                                        <td colSpan={6}>
                                          {validation?.is_validated &&
                                          <button type="submit" className="dspg-danger" onClick={() => navigate("/course-reg/print")}>
                                            Go to Printout
                                          </button>
                                          }
                                        </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </>
                                ) : (
                                  <div className="alert dspg-info">No Courses Registered Yet</div>
                                )}
                                </>
                              :''}
                              </>
                              {(semester && theCourses.length) ?
                              <div className="col-lg-9">
                                <DataTable value={theCourses} footer={`Total Units Selected: ${totalUnits}`} selectionMode={rowClick ? null : 'checkbox'} selection={selectedCourses} onSelectionChange={handleSelectionChange} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                    <Column field="code" header="Course Code"></Column>
                                    <Column field="title" header="Course Title"></Column>
                                    <Column field="unit" header="Course Unit"></Column>
                                    <Column field="semester" header="Semester"></Column>
                                </DataTable>
                                <div className="d-flex justify-content-between mt-2">
                                  <button className="btn dspg-warning" type="button" data-toggle="modal" data-target="#exampleModalCenter">Add more Courses</button>
                                  <button className="btn dspg-success" onClick={registerCourses}>Register Courses</button>
                                </div>
                            </div>
                              :""
                            }

                            
                                {(selectedCourses?.length) ? (
                                  <div className="bg-light col-lg-9">
                                    <table className="mt-3 col-lg-12">
                                      <thead className="dspg-dark">
                                        <th>Code</th>
                                        <th>Course</th>
                                        <th>Unit</th>
                                        <th>Semester</th>
                                      </thead>
                                      {selectedCourses?.map((list) => (
                                        <tbody>
                                          <td>{list.code}</td>
                                          <td>{list.title}</td>
                                          <td>{list.unit}</td>
                                          <td>{list.semester}</td>
                                        </tbody>
                                      ))}
                                      <tfoot>
                                      <tr>
                                        <td className="h5">
                                          Total Units
                                        </td>
                                        <td></td>
                                        <td className="h5">
                                          {totalUnits}
                                        </td>
                                        <td></td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                ) : (
                                  ""
                                )}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
          {(paymentComplete?.toLowerCase() !== 'full' && paymentComplete?.toLowerCase() !== "part") &&
          <div class="alert dspg-warning" role="alert">
          Please Pay Your School Fees First
        </div>
        }
        </div>

        <Footer />
      </div>
      }
      <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Search Courses</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {/*body */}
        <div className="form-group row">
          <div className="col-md-6 position-relative">
            <label>Search Course Code</label>
            <input className="form-control" placeholder="Search..." value={query} onChange={(e) => setQuery(e.target.value)}/>
          <div className="col-md-12 position-absolute bottom-50 start-50 bg-white" style={{zIndex:'100', cursor:'pointer'}}>

            {query.length >= 2 &&
            <>
            {filteredCourses?.map(filtered => 
              <h6 key={filtered.id} onClick={() => handleCourseSelection(filtered)}>{filtered.code} - {filtered.title}</h6>
              )}
              </>
            }
          </div>
          </div>
        </div>
        {/* <div class="form-group row">
            <div class="col-md-2">
              <label>Level</label>
              <select
                class="form-control"
                value={level}
                onChange={(e) =>
                  setLevel(e.target.value)
                }
              >
                <option>--Level--</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
              </select>
            </div>
            <div class="col-md-3">
              <label>Semester</label>
              <select
                class="form-control"
                value={semester}
                onChange={(e) =>
                  setSemester(e.target.value)
                }
              >
                <option>--Select Semester--</option>
                <option value="1">FIRST</option>
                <option value="2">SECOND</option>
              </select>
            </div>
            <div class="col-md-7">
              <label>Department</label>
              <select
                class="form-control"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option>
                  --Select Department--
                </option>
                {departments?.map(({id, department}) => (
                  <option
                    key={id}
                    value={department}
                  >
                    {department}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group row">
          <div class="col-md-12">
              <label>Academic Course</label>
              <select
                class="form-control"
                value={selectedCourses}
                onChange={handleCourseSelection}
              >
                <option>
                  --Select Academic Course--
                </option>
                {filteredCourses?.map(({course_id}) => (
                  <option
                    key={course_id.id}
                    value={JSON.stringify(course_id)}
                  >
                    {course_id.code} {course_id.title}
                  </option>
                ))}
              </select>
            </div>
          </div> */}
          
          {(selectedCourses?.length) ? (
            <div className="bg-light">
              <p className="breadcrumb-item">
                Selected Courses
              </p>
              <table className="col-lg-12">
                <thead className="dspg-dark">
                  <th>Code</th>
                  <th>Title</th>
                  <th>Unit</th>
                  <th>Semester</th>
                </thead>
                {selectedCourses?.map((list) => (
                  <tbody>
                    <td>{list.code}</td>
                    <td>{list.title}</td>
                    <td>{list.unit}</td>
                    <td>{list.semester}</td>
                    <td
                      onClick={() =>
                        handleCourseDelete1(list.id)
                      }
                    >
                      <Trash2 className="waves-effect align-self-center icon-dual-pink icon-sm" />
                    </td>
                  </tbody>
                ))}
                <tfoot>
                <tr>
                  <td className="h5">
                    Total Units
                  </td>
                  <td></td>
                  <td className="h5">
                    {totalUnits}
                  </td>
                  <td></td>
                  <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ) : (
            ""
          )}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
      </div>
    </div>
  </div>
</div>
    </div>
  );
};

export default CourseRegistration;
